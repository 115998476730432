<template>
  <div>
    <div class="flexbox full-screen" id="inicio" :style="bgPortrait(`home/Group-4030`,`png`)">
      <v-container>
        <v-row>
          <v-col cols xl="6" lg="6" md="12" sm="12" class="flexbox">
            <div>
              <h1 v-html="this.header.title" class="color-white h1-line-height roboto-condensed-regular"></h1>
              <p v-html="this.header.text" class="p-text-header color-white mt-4 roboto-condensed-light"></p>
              <a href="#contacto"><v-btn v-html="`¡contáctanos!`" class="roboto-condensed-bold btn-white mt-6" elevation="0" /></a>
            </div>
          </v-col>
          <v-col cols xl="6" lg="6" md="12" sm="12" class="flexbox">
              <v-img
                :src="image(`${this.header.image.folder}/${this.header.image.name}`,`${this.header.image.extension}`)"
                contain
                :height="this.header.image.height"
              />
          </v-col>
        </v-row>
      </v-container>
    </div>
    <div class="flexbox full-screen" id="nosotros">
      <v-container>
        <v-row>
          <v-col cols xl="5" lg="5" md="12" sm="12" class="flexbox offset-xl-4 offset-lg-4">
            <h2 class="quote color-gray subtitle-text roboto-condensed-bold" v-html="this.info_1.title"></h2>
          </v-col>
        </v-row>
        <v-row :style="bgPortrait(`home/Group-1956`,`png`)">
          <v-col cols xl="4" lg="4" md="12" sm="12" v-for="(item,index) in this.info_1.items" :key="index">
            <div class="flexbox image-container">
              <v-img
                :src="image(`${item.image.folder}/${item.image.name}`,`${item.image.extension}`)"
                contain
                :height="item.image.height"
              />
            </div>
            <h2 v-html="item.title" class="h2 text-center color-gray roboto-condensed-light"></h2>
            <p v-html="item.text" class="text-center p color-gray roboto-condensed-regular"></p>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <div class="flexbox full-screen color-light-gray-bg pt-14 pb-14" id="servicio">
      <v-container>
        <v-row>
          <v-col cols xl="4" lg="4" md="12" sm="12" class="flexbox offset-xl-1 offset-lg-1 pa-0">
            <div>
              <p class="quote color-gray subtitle-text roboto-condensed-bold">
                Crea o crece tu negocio
              </p>
              <p class="color-gray p-text roboto-condensed-light pl-7">
                Conocer el mercado es el primer paso para todo gran proyecto, o el faro que guía a los grandes negocios a buen puerto. Es por eso que te ayudamos a conocer y analizar tu mercado.
              </p>
              <p class="title-text robot-condensed-light color-blue pl-7 pt-15">
                Diseñamos el proyecto a tu medida.
              </p>
            </div>
          </v-col>
          <v-col cols xl="6" lg="6" md="12" sm="12">
              <v-img
                :src="image(`${this.info_2.image.folder}/${this.info_2.image.name}`,`${this.info_2.image.extension}`)"
                contain
                :height="500"
              />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols :xl="info.col_size" :lg="info.col_size" md="12" sm="12" v-for="(info,index) in this.info_3.row_a" :key="index">
            <div class="flexbox text-center image-gallery-container color-box-background">
              <div>
                <v-img
                  :src="image(`${info.image.folder}/${info.image.name}`,`${info.image.extension}`)"
                  :height="150"
                  contain
                >
                </v-img>
                <h4 class="color-gray mt-10" v-html="info.text"></h4>
              </div>
            </div>
          </v-col>
        </v-row>

        <v-row class="flexbox">
          <v-col cols :xl="info.col_size" :lg="info.col_size" md="12" sm="12" v-for="(info,index) in this.info_3.row_b" :key="index">
            <div class="flexbox text-center image-gallery-container color-box-background">
              <div>
                <v-img
                  :src="image(`${info.image.folder}/${info.image.name}`,`${info.image.extension}`)"
                  :height="150"
                  contain
                >
                </v-img>
                <h4 class="color-gray mt-10" v-html="info.text"></h4>
              </div>
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols xl="12" lg="12" md="12" sm="12" class="flexbox mt-10">
            <a href="#contacto"><v-btn class="btn-blue" elevation="0">¡Trabajemos juntos!</v-btn></a>
          </v-col>
        </v-row>
      </v-container>
    </div>"
    <div class="flexbox full-screen">
      <v-container>
        <v-row  :style="bgPortrait(`home/Group-1956`,`png`)">
          <v-col cols xl="7" lg="7" md="12" sm="12" class="flexbox-rigth">
              <v-img
                :src="image(`${this.info_4.image.folder}/${this.info_4.image.name}`,`${this.info_4.image.extension}`)"
                contain
                :height="this.header.image.height"
              />
          </v-col>
          <v-col cols xl="5" lg="5" md="12" sm="12" class="flexbox">

            <div>
              <p class="quote color-gray subtitle-text roboto-condensed-bold">
                Evalúa tus Servicios de forma Objetiva
              </p>
              <p class="color-gray p-text roboto-condensed-light pl-7">
                <span class="color-blue subtitle-text roboto-condensed-bold">Mystery Shoppers:</span>
                <ul>
                  <li>Evaluación de mi servicio</li>
                  <li>Evaluación de mi competencia</li>
                  <li>Comparación de precios</li>
                  <li>Revisión de estanterías y productos</li>
                  <li>Revisión de procesos</li>
                </ul>
              </p>
              <p class="title-text robot-condensed-light color-blue pl-7">
                <v-btn class="btn-blue" elevation="0">¿Cómo Funciona?</v-btn>
              </p>
            </div>

          </v-col>
        </v-row>
      </v-container>
    </div>
    <div class="flexbox full-screen color-light-gray-bg">
      <v-container>
        <v-row>
          <v-col cols xl="4" lg="4" md="12" sm="12" class="flexbox-left offset-xl-1 offset-lg-1">

           <div>
              <p class="quote color-gray subtitle-text roboto-condensed-bold">
                Sistema de Verificación
              </p>
              <p class="color-gray p-text roboto-condensed-light pl-7">
                <span class="color-blue subtitle-text roboto-condensed-bold"></span>
                <ul>
                  <li>Domicilios / Hogares / Empresas</li>
                  <li>Personas</li>
                  <li>Precios</li>
                  <li>Instalaciones de publicidad / productos</li>
                </ul>
              </p>
              <p class="title-text robot-condensed-light color-blue">
                <a href="#contacto"><v-btn class="btn-blue" elevation="0">¿Quiero saber más?</v-btn></a>
              </p>
            </div>


          </v-col>
          <v-col cols xl="6" lg="6" md="12" sm="12" class="flexbox">
              <v-img
                :src="image(`${this.info_5.image.folder}/${this.info_5.image.name}`,`${this.info_5.image.extension}`)"
                contain
                :height="480"
              />
          </v-col>
        </v-row>
      </v-container>
    </div>
    <div class="flexbox full-screen mt-5 mb-5">
      <v-container>
        <v-row>
          <v-col cols xl="12" lg="12" md="6" sm="6" class="flexbox">
              <p class="color-gray subtitle-text roboto-condensed-bold">
                <v-divider vertical>
                </v-divider>
                Casos de éxito
              </p>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6" md="6" sm="6" class="flexbox col-xl-8d col-lg-8d" v-for="(state,index) in this.info_6.array" :key="index">
            <span class="p-text roboto-condensed-regular" v-html="state"></span>
          </v-col>
        </v-row>
        <v-row class="flexbox">
          <v-col cols  md="6" sm="6" class="col-xl-5d col-lg-5d" v-for="(item,index) in 19" :key="index+15">
            <v-img
              :src="image(`home/brands/${index+1}`,`png`)"
              class="image-mobile-brand"
            />
          </v-col>
        </v-row>
      </v-container>
    </div>
    <div class="footer-screen blue-bg pt-7 pb-4" id="contacto" :style="bgPortraitMin(`home/x38__1_`,`png`)">
      <v-container>
        <v-row>
          <v-col cols xl="4" lg="4" md="12" sm="12" class="p-text roboto-condensed-regular flexbox">
            <div>
              <v-img 
                :src="image(`home/logotipo-blanco`,`svg`)"
                :width="225"
              />
              <v-row class="mt-2 mb-2">
                <v-col cols="4" class="flexbox offset-1">
                  <a
                      href="https://www.facebook.com/Indexmerca/"
                      target="_blank"
                      text
                      class="btn-menu-disabled roboto-condensed-bold"
                  >
                      <v-icon dark> mdi-facebook </v-icon>
                  </a>
                </v-col>
                <v-col cols="4" class="flexbox">
                  <a
                      href="https://www.linkedin.com/company/index-merca/"
                      target="_blank"
                      text
                      class="btn-menu-disabled roboto-condensed-bold"
                  >
                      <v-icon dark> mdi-linkedin </v-icon>
                  </a>
                </v-col>
              </v-row>
              <div class="color-white">
                <v-icon class="color-white mr-1 icon-min"> mdi-phone </v-icon>444 184 4008 / 444 211 9613<br/>
                <v-icon class="color-white mr-1 icon-min"> mdi-email </v-icon>contacto@indexmerca.com<br/>
                <v-icon class="color-white mr-1 icon-min"> mdi-map-marker </v-icon>Sierra Leona 418, Lomas 2a Secc.<br/>
                San Luis Potosí, S.L.P., México
              </div>
            </div>
          </v-col>
          <v-col cols xl="4" lg="4" md="12" sm="12" class=" offset-xl-4 offset-lg-4">
            <v-row>
              <v-col cols xl="6" lg="6" md="12" sm="12" class="pt-0 pb-0 pl-1 pr-1">
                <v-text-field
                  class="input-form"
                  placeholder="Nombre"
                  outlined
                ></v-text-field>
              </v-col>
              <v-col cols xl="6" lg="6" md="12" sm="12" class="pt-0 pb-0 pl-1 pr-1">
                <v-text-field
                  class="input-form"
                  placeholder="Empresa"
                  outlined
                ></v-text-field>
              </v-col>
              <v-col cols xl="6" lg="6" md="12" sm="12" class="pt-0 pb-0 pl-1 pr-1">
                <v-text-field
                  class="input-form"
                  placeholder="Correo"
                  outlined
                ></v-text-field>
              </v-col>
              <v-col cols xl="6" lg="6" md="12" sm="12" class="pt-0 pb-0 pl-1 pr-1">
                <v-text-field
                  class="input-form"
                  placeholder="Teléfono"
                  outlined
                ></v-text-field>
              </v-col>
              <v-col cols xl="12" lg="12" md="12" sm="12" class="pt-0 pb-0 pl-1 pr-1">
                <v-textarea
                  solo
                  elevation="0"
                  rows="3"
                  name="input-7-4"
                  label="Mensaje"
                ></v-textarea>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6" xl="6" lg="6" md="6" sm="6" class="flexbox-right offset-6">
                <v-btn v-html="`Enviar`"/>
              </v-col>
            </v-row>

          </v-col>
        </v-row>
      </v-container>
    </div>
  </div>
</template>

<script>
import {image,bgImage} from "@/mixins/image.js"

export default {
  name: "Home",
  mixins:[
    image,
    bgImage
  ],
  components: {

  },
  data: ()=>({
    header:{
      image:{
        name:"header",
        folder:"home",
        extension:"png",
        height:"445"   
      },
      title:"Es mejor acertar con toda la seguridad con una investigación de mercado que tener sólo el feeling.",
      text:"Nuestro compromiso es ser aliados de tu negocio; convertimos la información de estudios de mercados en estrategias efectivas que te permitan crecer o desarrollar tu nuevo negocio o evaluar como lo estas haciendo."
    },
    info_1:{
      title:"Porque somos tu mejor opción para investigación de mercado.",
      items:[
        {
          image:{
            name:"experiencia_icon",
            folder:"home",
            extension:"svg",
            height:"151"
          },
          title:"Experiencia",
          text:"Más de 10 años desarrollando estudios de mercados."
        },
        {
          image:{
            name:"cobertura_icon",
            folder:"home",
            extension:"svg",
            height:"213"
          },
          title:"Cobertura Nacional",
          text:"En INDEX contamos con colaboradores y aliados en toda la República Mexicana. No importa donde está tu negocio."
        },
        {
          image:{
            name:"sistema_index_icon",
            folder:"home",
            extension:"svg",
            height:"197"
          },
          title:"Sistema Index",
          text:"Innovamos nuestros métodos, confiamos en la tecnología y creamos un sistema propio para la ejecución y control de nuestros procesos."
        }
      ]
    },
    info_2:{
      image:{
        name:"crea-tu-negocio",
        folder:"home",
        extension:"png",
        height:"445"   
      },
      title:"Crea o crece tu negocio",
      text_a:"Conocer el mercado es el primer paso para todo gran proyecto, o el faro que guía a los grandes negocios a buen puerto. Es por eso que te ayudamos a conocer y analizar tu mercado.",
      text_b:"Diseñamos el proyecto a tu medida."
    },
    info_3:{
      row_a:[
        {
          image:{
            name:"1",
            folder:"home/icons",
            extension:"svg"
          },
          text:"Análisis de Consumidor",
          col_size:"3"
        },
        {
          image:{
            name:"2",
            folder:"home/icons",
            extension:"svg"
          },
          text:"Análisis de Consumidor",
          col_size:"3"
        },
        {
          image:{
            name:"3",
            folder:"home/icons",
            extension:"svg"
          },
          text:"Análisis de Consumidor",
          col_size:"3"
        },
        {
          image:{
            name:"4",
            folder:"home/icons",
            extension:"svg"
          },
          text:"Análisis de Consumidor",
          col_size:"3"
        }
      ],
      row_b:[
        {
          image:{
            name:"5",
            folder:"home/icons",
            extension:"svg"
          },
          text:"Análisis de Consumidor",
          col_size:"3"
        },
        {
          image:{
            name:"6",
            folder:"home/icons",
            extension:"svg"
          },
          text:"Análisis de Consumidor",
          col_size:"3"
        },
        {
          image:{
            name:"7",
            folder:"home/icons",
            extension:"svg"
          },
          text:"Análisis de Consumidor",
          col_size:"3"
        }
      ]
    },
    info_4:{
      image:{
        name:"Group-3376",
        folder:"home",
        extension:"png",
        height:"445"   
      },
      title:"Crea o crece tu negocio",
      text_a:"Conocer el mercado es el primer paso para todo gran proyecto, o el faro que guía a los grandes negocios a buen puerto. Es por eso que te ayudamos a conocer y analizar tu mercado.",
      text_b:"Diseñamos el proyecto a tu medida."
    },
    info_5:{
      image:{
        name:"Group-1094",
        folder:"home",
        extension:"png",
        height:"445"   
      },
      title:"Crea o crece tu negocio",
      text_a:"Conocer el mercado es el primer paso para todo gran proyecto, o el faro que guía a los grandes negocios a buen puerto. Es por eso que te ayudamos a conocer y analizar tu mercado.",
      text_b:"Diseñamos el proyecto a tu medida."
    },
    info_6:{
      title:"Casos de éxito",
      array:["San Luis Potosí","Querétaro","Aguascalientes","León","Chiapas","Cancún","Manzanillo","Puerto Vallarta"]
    }
  })
};
</script>
