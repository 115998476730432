<template>
  <v-app>
    <nav-bar />
    <v-main>
      <router-view />
    </v-main>
    <v-footer class="footer-background-color flexbox roboto-condensed-italic footer-font" dark>
      <img
        :src="image(`home/Group-426`,`png`)"
        height="23"
        contain
        class="mr-2"
      />
      © 2018-2019 Index. Todos los derechos reservados.
    </v-footer>
  </v-app>
</template>

<script>
import {image,bgImage} from "@/mixins/image.js";
import NavBar from './components/NavBar.vue';
export default {
  components: { NavBar },
  name: "App",
  mixins:[
    image,
    bgImage
  ],
  data: () => ({
    //
  }),
};
</script>
