<template>
    <div>
        <v-app-bar app color="white" elevation="0">
            <v-container class="flexbox pr-0 pl-0 d-none d-sm-none d-md-none d-lg-flex d-xl-flex">
                <img
                    alt="Vuetify Logo"
                    class="shrink"
                    contain
                    :src="image(`home/logotipo-color`,`svg`)"
                    transition="scale-transition"
                    height="35"
                />

                <v-spacer></v-spacer>

                <a
                    href="#inicio"
                    text
                    class="btn-menu roboto-condensed-bold"
                >
                    <span class="mr-2">Inicio</span>
                </a>
                <a
                    href="#nosotros"
                    text
                    class="btn-menu roboto-condensed-bold"
                >
                    <span class="mr-2">Nosotros</span>
                </a>
                <a
                    href="#servicio"
                    text
                    class="btn-menu roboto-condensed-bold"
                >
                    <span class="mr-2">Servicio</span>
                </a>
                <a
                    href="#contacto"
                    text
                    class="btn-menu roboto-condensed-bold"
                >
                    <span class="mr-2">Contacto</span>
                </a>
                <a
                    href="#inicio"
                    text
                    class="btn-menu-disabled roboto-condensed-bold"
                >
                    <span class="mr-2">Blog</span>
                </a>
                <a
                    href="https://www.facebook.com/Indexmerca/"
                    target="_blank"
                    text
                    class="btn-menu-disabled roboto-condensed-bold"
                >
                    <v-icon> mdi-facebook </v-icon>
                </a>
                <a
                    href="https://www.linkedin.com/company/index-merca/"
                    target="_blank"
                    text
                    class="btn-menu-disabled roboto-condensed-bold"
                >
                    <v-icon> mdi-linkedin </v-icon>
                </a>
            </v-container>
            <v-container class="flexbox pr-0 pl-0 d-flex d-sm-flex d-md-flex d-lg-none d-xl-none">
                <img
                    alt="Vuetify Logo"
                    class="shrink"
                    contain
                    :src="image(`home/logotipo-color`,`svg`)"
                    transition="scale-transition"
                    height="23"
                />

                <v-spacer></v-spacer>

  

                <v-menu offset-y>
                    <template v-slot:activator="{ on, attrs }">
                    <v-icon
                        color="primary"
                        v-bind="attrs"
                        v-on="on"                
                    >
                    mdi-menu
                    </v-icon>  
                    </template>
                    <v-list>
                        <v-list-item
                        v-for="(item, index) in items"
                        :key="index"
                        >
                        <a
                            :href="item.link"
                        >
                            <v-list-item-title>{{ item.title }}</v-list-item-title>
                        </a>
                        </v-list-item>
                        <v-list-item>
                            <a
                                href="https://www.facebook.com/Indexmerca/"
                                target="_blank"
                                text
                                class="btn-menu-disabled roboto-condensed-bold"
                            >
                                <v-icon> mdi-facebook </v-icon>
                            </a>
                        </v-list-item>
                        <v-list-item>
                            <a
                                href="https://www.linkedin.com/company/index-merca/"
                                target="_blank"
                                text
                                class="btn-menu-disabled roboto-condensed-bold"
                            >
                                <v-icon> mdi-linkedin </v-icon>
                            </a>
                        </v-list-item>
                    </v-list>
                </v-menu>


            </v-container>
        </v-app-bar>
    </div>
</template>

<script>
    import {image,bgImage} from "@/mixins/image.js"
    export default {
        mixins:[
            image,
            bgImage
        ],
        data: () => ({
            items: [
                { title: 'Inicio',link:"#inicio" },
                { title: 'Nosotros',link:"#nosotros" },
                { title: 'Servicios',link:"#servicio" },
                { title: 'Contacto',link:"#contacto" },
                { title: 'Blog',link:"#inicio" },
            ]
        }),
        methods: {
            scrollMeTo(refName) {
                var element = this.$refs[refName];
                var top = element.offsetTop;

                window.scrollTo(0, top);
            }
        }
    }
</script>

<style lang="scss" scoped>

</style>